<template>
  <div class="discover-container">
    <!-- 顶部 -->
    <van-row align="center" type="flex" justify="space-around">
      <!-- 导航栏 -->
      <van-col span="2">
        <van-nav-bar left-arrow @click-left="goBack"></van-nav-bar>
      </van-col>

      <!-- 搜索栏 -->
      <van-col span="22">
        <van-search v-model="hotsearch" show-action placeholder="请输入搜索关键词">
          <template #action>
            <van-button type="default" round size="small" color="#000" @click="searchFn(hotsearch)">搜索</van-button>
          </template>
        </van-search>
      </van-col>
    </van-row>

    <!-- 分类导航 -->
    <van-row>
      <van-col span="6">
        <van-sidebar v-model="activeKey">
          <van-sidebar-item v-for="item in categorylist" :key="item.id" :title="item.cateName" @click="sidebarFn(item.id)" />
        </van-sidebar>
      </van-col>

      <van-col class="category-col" span="18">
        <div v-for="item in categoryChildren" :key="item.id">
          <span class="title">{{ item.cateName }}</span>
          <div class="category">
            <van-grid :column-num="3" :border="false" :icon-size="60">
              <van-grid-item v-for="subCategory in item.children" :key="subCategory.id" @click="goCategory(subCategory.id)">
                <van-image width="60" height="60" fit="cover" :src="`${$host}/category/` + subCategory.iconPic">
                  <template v-slot:loading>
                    <van-loading vertical type="spinner" size="20" />
                  </template>
                </van-image>
                <span class="cateName">{{ subCategory.cateName }}</span>
              </van-grid-item>
            </van-grid>
          </div>
        </div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import { category } from '@/api/common';
export default {
  name: 'Discover',
  data() {
    return {
      hotsearch: '',
      activeKey: 0,
      categorylist: [],
      categoryChildren: []
    };
  },
  async created() {
    await this.getAllCategoryList();
    this.sidebarFn(this.categorylist[0]?.id);
  },
  methods: {
    async getAllCategoryList() {
      try {
        const {
          data: { result }
        } = await category({});
        this.buildCategoryTree(result);
      } catch (error) {
        console.log(error, 'error');
      }
    },

    buildCategoryTree(categories) {
      // 创建一个映射表来快速查找节点
      const nodesMap = {};
      categories.forEach((category) => {
        nodesMap[category.id] = category;
      });
      // 过滤出顶级分类（grade为0的节点）
      const topCategories = categories.filter((cat) => cat.grade === '0');
      // 构建子分类
      topCategories.forEach((topCat) => {
        this.setChildren(topCat, categories, nodesMap);
      });
      // 设置 this.categorylist 为顶级分类
      this.categorylist = topCategories;
    },

    setChildren(parent, allCategories, nodesMap) {
      parent.children = [];
      allCategories.forEach((child) => {
        if (child.parentId === parent.id) {
          parent.children.push(nodesMap[child.id]);
          this.setChildren(nodesMap[child.id], allCategories, nodesMap);
        }
      });
    },

    goBack() {
      this.$router.go(-1);
    },

    goCategory(id) {
      console.log(id, 'id');
      // this.$router.push({ path: '/Category', query: { id } });
    },

    searchFn(keyword) {
      console.log(keyword, 'keyword');
    },

    sidebarFn(id) {
      this.categoryChildren = this.categorylist.filter((item) => item.id === id)[0]?.children;
    }
  }
};
</script>

<style scoped lang="scss">
.discover-container {
  .category-col {
    padding-bottom: 60px;

    .title {
      display: block;
      padding: 16px 44px 16px 0;
      font-size: 14px;
      line-height: 20px;
    }

    .cateName {
      margin-top: 8px;
      font-size: 11px;
      line-height: 16px;
    }
  }
}
</style>

